import {Rankings} from "./rankings";
import {Admin} from "./admin";
import './App.css';
import React, {useState, useEffect} from "react";


function App() {
  const [admin, setAdmin] = useState(false);

  return (
    <div className="App">
      <div className="title">
        <div className="bowlingBall">
          <div className="hole1"></div>
          <div className="hole2"></div>
          <div className="hole3"></div>
        </div>
        <div className="titleText">
          <h1>The</h1>
          <h1>Boys</h1>
          <h1>Bowling</h1>
        </div>
      </div>
      {
        admin
        ? <Admin />
        : <Rankings />
      }
      <button className="adminButton" onClick={() => setAdmin(!admin)}>{admin ? "-" : "+"}</button>
    </div>
  );
}

export default App;
