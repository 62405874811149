import React, { useState } from "react";
import { endpoint } from "./rankings";
import axios from "axios";

export const Admin = () => {
    const [password, setPassword] = useState("");

    const [playerName, setPlayerName] = useState("");

    const [gamePlayerName, setGamePlayerName] = useState("");
    const [gameScoreString, setGameScoreString] = useState("");
    const [gameNum, setGameNum] = useState(0);
    const [gameSeasonNumber, setGameSeasonNumber] = useState(0);
    const [gameDatePlayed, setGameDatePlayed] = useState("");

    const addPlayer = (e) => {
        e.preventDefault();

        axios.post(endpoint + "/players/add", {
            name: playerName
        })
        .catch((err) => {
            console.log(err);
        })

        setPlayerName("");
    }

    const addGame = (e) => {
        e.preventDefault();

        axios.post(endpoint + "/scores/add", {
            playerName: gamePlayerName,
            scoreString: gameScoreString,
            datePlayed: gameDatePlayed,
            seasonNum: gameSeasonNumber,
            gameNum: gameNum
        }).catch((err) => {
            console.error(err);
        })

        setGameScoreString("");
        setGameNum(0);
    }
    const [locked, setLocked] = useState(true);
    if (locked) {
        return(
            <div>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (password == "cumlord") {
                        setLocked(false);
                    }
                    setPassword("");
                }}>
                    <h2>Enter Password First</h2>
                    <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <input type="submit" value="Enter" />
                </form>
            </div>
        )
    }

    return(
        <div>
            <form onSubmit={addPlayer}>
                <h2>Add Player</h2>
                <input type="text" placeholder="Enter Player Name..." value={playerName} onChange={(e) => setPlayerName(e.target.value)} />
                <input type="submit" value="Enter" />
            </form>
            <form onSubmit={addGame}>
                <h2>Add Game</h2>
                <input type="text" placeholder="Enter Player Name..." value={gamePlayerName} onChange={(e) => setGamePlayerName(e.target.value)} />
                <input type="text" placeholder="Enter Score String..." value={gameScoreString} onChange={(e) => setGameScoreString(e.target.value)} />
                <input type="number" placeholder="Enter Game Number.." value={gameNum} onChange={(e) => setGameNum(e.target.value)} />
                <input type="number" placeholder="Enter Season Number..." value={gameSeasonNumber} onChange={(e) => setGameSeasonNumber(e.target.value)} />
                <input type="date" value={gameDatePlayed} onChange={(e) => setGameDatePlayed(e.target.value)} />
                <input type="submit" value="Enter" />
            </form>
        </div>
    )
}