import React, { useEffect, useState } from "react";
import { endpoint } from "./rankings";
import axios from "axios";

export const Rank = ({name, identifier, number, link}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    axios.get(endpoint + link)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      })
  }, [])

  if (loading) return <></>
  if (error != "") return <h2 style={{color:"red"}}>Error Loading</h2>

  return(
      <div className="rankings">
        <div className="podiums">
          <div className="podiumParent">
            <p>{data[1][identifier]}</p>
            <div className="podium p2">
              <p>{data[1][number]}</p>
            </div>
          </div>
          <div className="podiumParent">
            <p>{data[0][identifier]}</p>
            <div className="podium p1">
              <p>{data[0][number]}</p>
            </div>
          </div>
          <div className="podiumParent">
            <p>{data[2][identifier]}</p>
            <div className="podium p3">
              <p>{data[2][number]}</p>
            </div>
          </div>
        </div>
        <h1>{name}</h1>
      </div>
  )
}