import React, {useState, useEffect} from "react";
import axios from "axios";
import { Rank } from "./rank";
import { Stat } from "./stat";

export const endpoint = "https://theboysbowling.co.uk:8000";
// export const endpoint = "http://localhost:8000";

export const Rankings = () => {
    return(
      <div className="ranks">
        <Rank link="/players" name="Highest Overall Score" identifier="name" number="totalScore" />
        <Rank link="/best-games" name="Best Game" identifier="name" number="score" />
        <Rank link="/worst-games" name="Worst Game" identifier="name" number="score" />
        <Stat link="/bowls-thrown" name="Bowls Thrown" />
        <Stat link="/strikes" name="Strikes" />
        <Stat link="/spares" name="Spares" />
        <Stat link="/gutters" name="Gutters" />
        <Rank link="/strikes/rank" name="Most Strikes" identifier="name" number="total" />
        <Rank link="/spares/rank" name="Most Spares" identifier="name" number="total" />
        <Rank link="/gutters/rank" name="Most Gutters" identifier="name" number="total" />
      </div>
    )
    // RANK FOR MOST STRIKES AND SPARES
    // RANK FOR MOST GUTTER BALLS
    // STAT FOR GUTTER BALLS
}