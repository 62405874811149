import axios from "axios";
import React, { useEffect, useState } from "react";
import { endpoint } from "./rankings";

export const Stat = ({link, name}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState("");

  useEffect(() => {
    axios.get(endpoint + link)
      .then((res) => {
        setData(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      })
  }, [])

  if (loading) return <></>
  if (error != "") return <h2 style={{color:"red"}}>Error Loading</h2>

  return(
      <div className="rankings">
        <div className="stat">
          <h2>{data}</h2>
          <h1>{name}</h1>
        </div>
      </div>
  )
}